<script>
import { defineAsyncComponent, ref, shallowRef, watch } from "vue";

export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    path: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const result = shallowRef(() => null);
    const name = ref("" || props.name);

    watch(
      () => props.name,
      (newName) => {
        name.value = newName;
        result.value = defineAsyncComponent(promise);
      }
    );

    const promise = () => {
      switch (props.path) {
        case "":
          return import(`../../../assets/svg/icon/${name.value}.svg`);
        case "cards":
          return import(`../../../assets/svg/cards/${name.value}.svg`);
        case "alerts":
          return import(`../../../assets/svg/alerts/${name.value}.svg`);
        case "pattern":
          return import(`../../../assets/svg/pattern/${name.value}.svg`);
        case "gateway":
          return import(`../../../assets/svg/gateway/${name.value}.svg`);
        case "filetype":
          return import(`../../../assets/svg/filetype/${name.value}.svg`);
        case "services":
          return import(`../../../assets/svg/services/${name.value}.svg`);
        case "./":
          return import(`../../../assets/svg/${name.value}.svg`);
      }
    };
    result.value = defineAsyncComponent(promise) || '';

    return { result };
  },
};
</script>

<template>
  <component :is="result" />
</template>
